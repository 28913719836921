import axios from '../../plugins/axios';
import { router } from '../../router';

export default {
  state: {
    userData: null,
    users: [],
    logInError: null,
  },

  mutations: {
    setUserData(state, data) {
      state.userData = data;
    },

    setUsers(state, data) {
      state.users = data;
    },

    setLogInError(state, error) {
      state.logInError = error;
    },
  },

  actions: {
    doLogin({ commit }, { username, password }) {
      axios
        .post(`${process.env.VUE_APP_API_URL}/login/`, {
          username,
          password,
        })
        .then(response => {
          localStorage.setItem('user', JSON.stringify(response.data));
          commit('setUserData', response.data);
          commit('setLogInError', null);
          router.push({ name: 'home' });
        })
        .catch(error => {
          commit('setLogInError', error.response.data);
          commit('setUserData', null);
        });
    },

    doLogout({ commit }) {
      localStorage.removeItem('user');
      commit('setUserData', null);
      commit('setCategoriesData', []);
      router.push({ name: 'login' });
    },

    getUsers({ commit }) {
      axios.get(`${process.env.VUE_APP_API_URL}/users/`).then(response => {
        commit('setUsers', response.data);
      });
    },
  },
};
