import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const lazyLoad = (view) => {
  return () => import(`@/views/${view}.vue`);
};

export const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: lazyLoad('Home'),
    },
    {
      path: '/category-explore',
      name: 'category-explore',
      component: lazyLoad('CategoryExplore'),
    },
    {
      path: '/expense-all-explore',
      name: 'expense-all-explore',
      component: lazyLoad('AllExpensesExplore'),
    },
    {
      path: '/limits',
      name: 'limits',
      component: lazyLoad('Limits'),
    },
    {
      path: '/login',
      name: 'login',
      component: lazyLoad('Login'),
    },
  ],
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  next();
});
