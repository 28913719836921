import axios from "../../plugins/axios";

export default {
    state: {
        categoriesData: [],
    },

    mutations: {
        setCategoriesData(state, data) {
            state.categoriesData = data;
        },
    },

    actions: {
        getCategoriesData({ commit }) {
            return axios
                .get(`${process.env.VUE_APP_API_URL}/categories/`)
                .then((response) => {
                    commit("setCategoriesData", response.data);
                });
        },
    },
};
