<template>
  <v-menu v-if="userData" bottom offset-y right>
    <template v-slot:activator="{ on }">
      <v-avatar color="secondary" v-on="on">
        <span class="white--text headline">{{ initials }}</span>
      </v-avatar>
    </template>
    <v-list>
      <v-list-item>
        <v-list-item-title>{{ userData.username }}</v-list-item-title>
        <v-spacer></v-spacer>
        <v-btn text>
          <v-icon @click="logout">{{ mdiLogout }}</v-icon>
        </v-btn>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mdiLogout } from '@mdi/js';

import { mapState } from 'vuex';

export default {
  data: () => ({
    mdiLogout,
  }),

  computed: {
    ...mapState({
      userData: (state) => state.users.userData,
    }),

    initials() {
      return `${this.userData.username[0]}${this.userData.username[1]}`;
    },
  },

  methods: {
    logout() {
      this.$store.dispatch('doLogout');
    },
  },
};
</script>

<style scoped>
.v-avatar {
  cursor: pointer;
}
</style>
