<template>
  <v-app-bar collapse-on-scroll app>
    <v-app-bar-nav-icon @click="toggleDrawer"></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <UserInfo />
  </v-app-bar>
</template>

<script>
import UserInfo from '@/components/toolbar/UserInfo';

export default {
  props: {
    showDrawer: Boolean,
  },

  components: { UserInfo },

  methods: {
    toggleDrawer() {
      this.$emit('update:showDrawer', !this.showDrawer);
    },
  },
};
</script>
