import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import { router } from './router';
import store from './store/index';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import './registerServiceWorker'

Vue.config.productionTip = false;

Vue.filter('currency', function(value) {
  const floatValue = parseFloat(value);
  return isNaN(floatValue) ? value : floatValue.toLocaleString('hr');
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
