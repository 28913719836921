import Vue from "vue";
import axios from "../../plugins/axios";
import colors from "vuetify/lib/util/colors";
import { getCategoryColors } from "../../utils/categoryColors";

export default {
    state: {
        expenses: [],
        expensesSum: [],
        expensesLoaded: false,
        expensesSumLoaded: false,
        saveError: null,
    },

    getters: {
        expenseSumByUser(state, _, rootState) {
            return rootState.users.users.map((user) => {
                const sum = state.expenses
                    .filter((expense) => {
                        return expense.user == user.id;
                    })
                    .reduce(
                        (accumulator, currentValue) =>
                            parseFloat(accumulator) +
                            parseFloat(currentValue.value),
                        0
                    );

                return { user: user.username, sum };
            });
        },

        expenseSumByCategory(state, getters, rootState) {
            const summedData = rootState.categories.categoriesData.map(
                (category) => {
                    const expensesByCategory = state.expenses.filter(
                        (expense) => {
                            return expense.category == category.id;
                        }
                    );
                    const sum = expensesByCategory.reduce(
                        (accumulator, currentValue) =>
                            parseFloat(accumulator) +
                            parseFloat(currentValue.value),
                        0
                    );
                    const { limit, limitDiff } =
                        getters.limitExpenseDiffForCategoryData(
                            category.name,
                            sum
                        );

                    return {
                        category: category.name,
                        value: sum.toFixed(2),
                        limit,
                        limitDiff,
                    };
                }
            );

            return summedData.sort((a, b) => b.value - a.value);
        },

        expenseAllSum(state) {
            return state.expenses.reduce(
                (accumulator, currentValue) =>
                    parseFloat(accumulator) + parseFloat(currentValue.value),
                0
            );
        },

        expensesSumValues(state) {
            return state.expensesSum.map(
                (expenseSum) => expenseSum.sum_expenses
            );
        },

        expensesStats(_, getters) {
            const maxExpenses = Math.max(...getters.expensesSumValues);
            const minExpenses = Math.min(...getters.expensesSumValues);
            const sumExpenses = getters.expensesSumValues
                .reduce(
                    (accumulator, currentValue) =>
                        accumulator + parseFloat(currentValue),
                    0
                )
                .toFixed(2);
            const avgExpenses = (
                sumExpenses / getters.expensesSumValues.length
            ).toFixed(2);

            return {
                maxExpenses,
                minExpenses,
                sumExpenses,
                avgExpenses,
            };
        },

        expenseSumByCategoryChartData(_, getters, rootState) {
            const expensesSumByCategory = getters.expenseSumByCategory;
            const categoriesData = rootState.categories.categoriesData;
            const labels = expensesSumByCategory.map((entry) => entry.category);
            const values = expensesSumByCategory.map((entry) => entry.value);
            const valuesLimits = expensesSumByCategory.map((entry) => {
                return isNaN(entry.limit.value) ? 0 : entry.limit.value;
            });
            const categoryColors = expensesSumByCategory.map((entry) =>
                getCategoryColors(entry.category, categoriesData)
            );

            return {
                labels,
                datasets: [
                    {
                        data: valuesLimits,
                        backgroundColor: "rgba(0, 0, 0, 0.2)",
                    },
                    { data: values, backgroundColor: categoryColors },
                ],
            };
        },

        expenseSumChartData(state, getters) {
            const labels = state.expensesSum.map((expenseSum) =>
                expenseSum.year_month.substring(0, 7)
            );

            return {
                labels,
                datasets: [
                    {
                        data: Array(getters.expensesSumValues.length).fill(
                            getters.expensesStats.avgExpenses
                        ),
                        backgroundColor: "rgba(0, 0, 0, 0)",
                        borderColor: colors.yellow.lighten1,
                        pointRadius: 0,
                        pointHoverRadius: 0,
                        type: "line",
                    },
                    {
                        data: getters.expensesSumValues,
                        backgroundColor: colors.red.lighten1,
                    },
                ],
            };
        },
    },

    mutations: {
        setExpenses(state, data) {
            state.expenses = data;
        },

        setExpensesSum(state, { data, startDate, endDate }) {
            const genDateRange = (startDate, endDate) => {
                let midYears = [];
                let endDateMonths = [];

                const numOfStartDateMonths =
                    endDate.year - startDate.year === 0
                        ? endDate.month - startDate.month + 1
                        : 13 - startDate.month;

                const startDateMonths = Array(numOfStartDateMonths)
                    .fill()
                    .map((_, idx) => startDate.month + idx);

                if (endDate.year - startDate.year > 0) {
                    endDateMonths = Array(endDate.month)
                        .fill()
                        .map((_, idx) => endDate.month - idx)
                        .reverse();

                    midYears = Array(endDate.year - 1 - startDate.year)
                        .fill()
                        .map((_, idx) => endDate.year - 1 - idx)
                        .reverse();
                }

                const startDates = startDateMonths.map((month) => {
                    const fullMonth = month < 10 ? `0${month}` : `${month}`;
                    return `${startDate.year}-${fullMonth}-01`;
                });
                const endDates = endDateMonths.map((month) => {
                    const fullMonth = month < 10 ? `0${month}` : `${month}`;
                    return `${endDate.year}-${fullMonth}-01`;
                });
                const midDates = midYears.map((year) => {
                    const months = Array(12)
                        .fill()
                        .map((_, idx) => idx + 1);

                    return months.map((month) => {
                        const fullMonth = month < 10 ? `0${month}` : `${month}`;
                        return `${year}-${fullMonth}-01`;
                    });
                });

                return [...startDates, ...midDates.flat(), ...endDates];
            };

            const fillMissingDates = (data, dateRange) => {
                const filledData = [];

                dateRange.forEach((range) => {
                    const indexOfRange = data.findIndex(
                        (d) => range === d.year_month
                    );

                    if (indexOfRange < 0) {
                        const category = data.length ? data[0].category : 0;
                        const categoryName = data.length
                            ? data[0].category_name
                            : "unknown";

                        filledData.push({
                            category,
                            category_name: categoryName,
                            sum_expenses: "0",
                            year_month: range,
                        });
                    } else {
                        filledData.push(data[indexOfRange]);
                    }
                });

                return filledData;
            };

            const dateRange = genDateRange(startDate, endDate);
            state.expensesSum = fillMissingDates(data, dateRange);
        },

        setExpensesLoaded(state, data) {
            state.expensesLoaded = data;
        },

        setExpensesSumLoaded(state, data) {
            state.expensesSumLoaded = data;
        },

        removeDeletedExpense(state, data) {
            const expenseToDelete = state.expenses.findIndex(
                (expense) => expense.id === data.id
            );
            state.expenses.splice(expenseToDelete, 1);
        },

        setUpdatedExpense(state, { data, monthYear }) {
            const expenseToUpdate = state.expenses.findIndex(
                (expense) => expense.id == data.id
            );

            if (data.date.includes(monthYear)) {
                Vue.set(state.expenses, expenseToUpdate, data);
            } else {
                Vue.delete(state.expenses, expenseToUpdate);
            }
        },

        setSavedExpense(state, { data, monthYear }) {
            if (data.date.includes(monthYear)) state.expenses.push(data);
        },

        setSaveError(state, data) {
            state.saveError = data;
        },
    },

    actions: {
        async getExpenses({ commit }, { date }) {
            commit("setExpensesLoaded", false);

            const request = axios.get(
                `${process.env.VUE_APP_API_URL}/expenses/${date.year}/${date.month}/`
            );
            const response = await request;

            commit("setExpenses", response.data);
            commit("setExpensesLoaded", true);
        },

        async getExpensesSum(
            { commit },
            { category_id, startDate, endDate, excludeCategories }
        ) {
            commit("setExpensesSumLoaded", false);

            const expenseSumUrl = `/expenses-sum/${startDate.year}/${startDate.month}/${endDate.year}/${endDate.month}`;
            let url = category_id
                ? `${process.env.VUE_APP_API_URL}/categories/${category_id}${expenseSumUrl}`
                : `${process.env.VUE_APP_API_URL}${expenseSumUrl}`;
            let queryParams = "";

            if (excludeCategories && excludeCategories.length) {
                excludeCategories.forEach((category, index) => {
                    queryParams = !index
                        ? `${queryParams}?exclude_category=${category}`
                        : `${queryParams}&exclude_category=${category}`;
                });
            }

            url = `${url}${queryParams}`;

            const request = axios.get(url);
            const response = await request;

            commit("setExpensesSum", {
                data: response.data,
                startDate,
                endDate,
            });
            commit("setExpensesSumLoaded", true);
        },

        deleteExpense({ commit }, { expense }) {
            axios.delete(`${expense.url}`).then(() => {
                commit("removeDeletedExpense", expense);
            });
        },

        updateExpense({ rootState, commit }, { expense }) {
            expense.value = expense.value.replace(",", ".");

            return axios
                .patch(`${expense.url}`, {
                    date: expense.date,
                    category: expense.category,
                    value: expense.value,
                })
                .then((response) => {
                    commit("setUpdatedExpense", {
                        data: response.data,
                        monthYear: rootState.menu.monthYear,
                    });
                    commit("setSaveError", null);
                })
                .catch((error) => {
                    commit("setSaveError", error.response.data);
                });
        },

        saveExpense({ rootState, commit }, { expense }) {
            expense.value = expense.value.replace(",", ".");

            return axios
                .post(`${process.env.VUE_APP_API_URL}/expenses/`, {
                    ...expense,
                })
                .then((response) => {
                    commit("setSavedExpense", {
                        data: response.data,
                        monthYear: rootState.menu.monthYear,
                    });
                    commit("setSaveError", null);
                })
                .catch((error) => {
                    commit("setSaveError", error.response.data);
                });
        },
    },
};
