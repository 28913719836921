import Vue from 'vue';
import axios from '../../plugins/axios';

export default {
    state: {
        limitsData: [],
        limitsLoaded: false,
        saveError: null,
    },

    getters: {
        limitsHaveMissingValue(state, _, rootState) {
            return (
                state.limitsData.length !==
                rootState.categories.categoriesData.length
            );
        },

        limitsCategories(state) {
            return state.limitsData.map((limit) => limit.category_name);
        },

        limitSum(state) {
            return state.limitsData.reduce(
                (accumulator, currentValue) =>
                    parseFloat(accumulator) + parseFloat(currentValue.value),
                0,
            );
        },

        limitForCategoryData(state, _, rootState) {
            return rootState.categories.categoriesData.map((category) => {
                const limitForCategory = state.limitsData.find(
                    (limit) => limit.category == category.id,
                );
                if (limitForCategory) {
                    return limitForCategory;
                } else {
                    return {
                        category: category.id,
                        category_name: category.name,
                        value: '-',
                    };
                }
            });
        },

        limitExpenseDiffForCategoryData(state, getters) {
            return (categoryName, expenseSum) => {
                const limitForCategory = state.limitsData.find(
                    (limit) => limit.category_name === categoryName,
                );
                const limitsCategories = getters.limitsCategories;

                return limitsCategories.includes(categoryName)
                    ? {
                          limit: limitForCategory,
                          limitDiff: limitForCategory.value - expenseSum,
                      }
                    : {
                          limit: { value: '-' },
                          limitDiff: '-',
                      };
            };
        },
    },

    mutations: {
        setLimitsData(state, data) {
            state.limitsData = data;
        },

        setLimitsLoaded(state, data) {
            state.limitsLoaded = data;
        },

        setSavedLimit(state, data) {
            state.limitsData.push(data);
        },

        setUpdatedLimit(state, data) {
            const limitToUpdate = state.limitsData.findIndex(
                (limit) => limit.id == data.id,
            );

            Vue.set(state.limitsData, limitToUpdate, data);
        },

        setSaveError(state, data) {
            state.saveError = data;
        },
    },

    actions: {
        copyLimitsFromPreviousMonth({ _ }, { year, month }) {
            return axios.post(
                `${process.env.VUE_APP_API_URL}/limits/${year}/${month}/copy-previous-month`,
            );
        },

        getLimits({ commit }, { date }) {
            commit('setLimitsLoaded', false);

            return axios
                .get(`${process.env.VUE_APP_API_URL}/limits/${date.year}/${date.month}/`)
                .then((response) => {
                    commit('setLimitsData', response.data);
                    commit('setLimitsLoaded', true);
                });
        },

        saveLimit({ commit }, { limit }) {
            return axios
                .post(`${process.env.VUE_APP_API_URL}/limits/`, {
                    ...limit,
                })
                .then((response) => {
                    commit('setSavedLimit', response.data);
                    commit('setSaveError', null);
                })
                .catch((error) => {
                    commit('setSaveError', error.response.data);
                });
        },

        updateLimit({ commit }, { limit }) {
            limit.value = limit.value.replace(',', '.');

            return axios
                .patch(`${limit.url}`, {
                    date: limit.date,
                    category: limit.category,
                    value: limit.value,
                })
                .then((response) => {
                    commit('setUpdatedLimit', response.data);
                    commit('setSaveError', null);
                })
                .catch((error) => {
                    console.log(error);
                    commit('setSaveError', error.response.data);
                });
        },
    },
};
