<template>
  <v-navigation-drawer app v-model="show" width="356">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{ $t('montrack') }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ $t('montrackSubtitle') }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list expand nav>
      <v-list-item link to="/">
        <v-list-item-icon>
          <v-icon>{{ mdiHome }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="title">{{ $t('home') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link to="/expense-all-explore">
        <v-list-item-icon>
          <v-icon>{{ mdiCalendar }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="title">{{
            $t('expensesAll')
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link to="/category-explore">
        <v-list-item-icon>
          <v-icon>{{ mdiViewDashboard }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="title">{{
            $t('expensesByCategory')
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link to="/limits">
        <v-list-item-icon>
          <v-icon>{{ mdiAlert }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="title">{{
            $t('limitEdit')
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mdiAlert, mdiCalendar, mdiHome, mdiViewDashboard } from '@mdi/js';

export default {
  props: {
    showDrawer: Boolean,
  },

  data: () => ({
    mdiAlert,
    mdiCalendar,
    mdiHome,
    mdiViewDashboard,
  }),

  computed: {
    show: {
      get() {
        return this.showDrawer;
      },
      set(value) {
        this.$emit('update:showDrawer', value);
      },
    },
  },
};
</script>
