import Vue from 'vue';
import Vuex from 'vuex';
import categories from './modules/categories';
import expenses from './modules/expenses';
import limits from './modules/limits';
import menu from './modules/menu';
import users from './modules/users';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    categories,
    expenses,
    limits,
    menu,
    users,
  },
});
