<template>
  <v-app>
    <template v-if="showToolbar">
      <NavigationDrawer :showDrawer.sync="showDrawer" />
      <Toolbar :showDrawer.sync="showDrawer" />
    </template>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import NavigationDrawer from '@/components/drawer/NavigationDrawer';
import Toolbar from '@/components/toolbar/Toolbar';

export default {
  components: {
    NavigationDrawer,
    Toolbar,
  },

  data: () => ({
    showDrawer: false,
  }),

  computed: {
    route() {
      return this.$route.name;
    },

    isLoginRoute() {
      return this.route === 'login';
    },

    showToolbar() {
      return this.route && !this.isLoginRoute;
    },
  },

  beforeCreate() {
    if (!this.$store.state.users.userData && localStorage.getItem('user')) {
      const userData = JSON.parse(localStorage.getItem('user'));
      this.$store.commit('setUserData', userData);
    }
  },
};
</script>
