export default {
  state: {
    monthYear: null,
    startMonthYear: null,
    endMonthYear: null,
    expansesFilter: '',
  },

  mutations: {
    setMonthYear(state, data) {
      state.monthYear = data;
    },

    setStartMonthYear(state, data) {
      state.startMonthYear = data;
    },

    setEndMonthYear(state, data) {
      state.endMonthYear = data;
    },

    setExpansesFilter(state, data) {
      state.expansesFilter = data;
    },
  },
};
